import React from 'react';
import '../../App.scss';
import Input, {TypeEmail} from "../../bootstrap/input";
import {useState} from "react";
import UserWithEmailPasswdGetTextByCode from "../../firebase/UserWithEmailAndPassword_errors";

const loginModelDefault = [{
    name: "emailLogin",
    label: "Votre adresse mail",
    type: TypeEmail,
}
];
function ResetPasswordPage(props) {
    const [Loading, setLoading] = useState(false);
    const [Model, setModel] = useState(loginModelDefault);
    const [ErrorMessage, setErrorMessage] = useState(null);
    const [FinalStatus, setFinalStatus] = useState(null);

    const onChange = (name, newValue) => {
        if(ErrorMessage !== null){
            setErrorMessage(null);
        }

        if(FinalStatus !== null){
            setFinalStatus(null);
        }

        let NewModel = structuredClone(Model);
        let objIndex = NewModel.findIndex((obj => obj.name === name));
        NewModel[objIndex].value = newValue;
        setModel(NewModel);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(FinalStatus === "OK"){
            return;
        }

        let param = [];
        let objIndex = Model.findIndex((obj => obj.name === "emailLogin"));

        if((Model[objIndex].value !== undefined && Model[objIndex].value === "") || Model[objIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/invalid-email'));
            return;
        }

        param["data"] = Model[objIndex].value;

        if(Loading === true){
            return;
        }
        setLoading(true);

        props.session.ReqPost("/api/reset-password", function (response) {
            return response.text().then(function(text) {
                setLoading(false);
                if(text === "OK"){
                    setFinalStatus("OK");
                }else{
                    setFinalStatus("NOK");
                }
            });
        }, param)
    }

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                <h2>Réinitialiser votre mot de passe</h2>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    {Model.map(input => Input(input, ErrorMessage !== null, onChange))}

                    {ErrorMessage !== null && <div className="alert alert-danger"
                                                   role="alert">
                        {ErrorMessage}
                    </div> }

                    <div id="emailHelp"
                         className="form-text mb-3">Un email permettant de réinitialiser votre mot de passe vous sera envoyé.
                    </div>

                    { FinalStatus !== null && FinalStatus === "OK" && <div className="alert alert-info"
                                                                            role="alert">
                        <strong>Un email contenant un lien de réinitialisation a été envoyé</strong> <small>sauf si votre adresse n'est pas enregistrée dans notre base de données.</small>
                    </div> }


                    { FinalStatus !== null && FinalStatus === "NOK" && <div className="alert alert-danger"
                         role="alert">
                        Une erreur s'est produite. Nous ne sommes pas parvenus réinitialiser votre compte.
                    </div> }


                    {Loading === false &&
                        <button type="submit"
                                className="btn btn-primary">Envoyer
                        </button>}
                    {Loading === true &&
                        <button className="btn btn-primary"
                                type="button"
                                disabled>
                        <span className="spinner-grow spinner-grow-sm me-1"
                              role="status"
                              aria-hidden="true"></span>
                            Chargement...
                        </button>}

                </form>
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default ResetPasswordPage;
