import modeleStep1 from "./modeleStep1";
import FormPanel from "../../../helper/components/FormPanel";
import "./style.scss"
function RegisterFormStep1(props) {

    let CurModel= modeleStep1.map(a => {return {...a}});;

    CurModel.forEach((m, i) => {
        if(props.candidature[m.name] !== undefined && props.candidature[m.name] !== null && props.candidature[m.name] !== ""){
            console.log("Ok pour " + m.name +" >> val : " + props.candidature[m.name])
            CurModel[i].value = props.candidature[m.name];
        }
    })

    let emailIndex = CurModel.findIndex((obj => obj.name === "Email"));






    const submitCallback = (errorcallback, model) => {
        let PostAr = {};
        let PostPayload = {};
        model.forEach(m => {
            PostPayload[m.name] = m.value;
        })
        PostAr['Candidat'] = PostPayload;
        props.PostData(PostAr, 1, (ErrorsMessages) => {
            console.log(ErrorsMessages);
            errorcallback(ErrorsMessages);
        });
     }

    return (
        <>
            <div className="alert mt-4  alert-info maxWidth m-auto"
                 role="alert">
                (*) = Les champs marqués d'une étoile sont obligatoire.
            </div>
        <div className="card mt-4 maxWidth m-auto">
            <div className="card-header">
                Données personelles
            </div>
            <div className="card-body p-md-5">

        <FormPanel btnName="Continuer" btnLoading="Enregistrement en cours..." submitCallback={submitCallback}  model={CurModel}   />

        </div>
</div>
        </>

    );
}

export default RegisterFormStep1;