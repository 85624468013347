import React from "react";
import "./style.scss"

import IcoCheck from "./check.svg";
import {useNavigate} from "react-router-dom";
type ListStep = {Num: number, text: string, State: number, Current: boolean};
/* State :
   0 = Pending
   1 = Valid
 */
type Props = {
    ListStep?: ListStep,
};
function ProgressStep(props: Props){
    const navigate = useNavigate();

    const RedirectToStep = (step) => {
        navigate("/mescandidatures/"+props.ConcoursId+"/"+props.CandidatureId+"/"+step);
    }

    return <div className="container d-flex flex-column justify-content-center align-items-center">

            <div className="progresses">
                {props.ListStep.map(function (data, i) {
                    if(data.SpaceType === true){
                        return <span key={i} className={((props.MaxStep > props.ListStep[i-1].Num) ? 'line' : 'lineInProgress' ) + " secondary"}></span>
                    }

                return  <div key={i} className={((props.MaxStep > data.Num) ? 'stepsIco' : 'stepsInProgress' ) + ((data.Num !== props.CurStep && props.MaxStep >= data.Num) ? " LinkActif" : '' )+((data.Num !== props.CurStep? " secondary" : ""))} onClick={function () {
                    if(data.Num !== props.CurStep && props.MaxStep >= data.Num){
                        RedirectToStep(data.Num);
                    }
                }}>
                    {((props.MaxStep > data.Num) ? <img src={IcoCheck} width={21} /> : data.Num )}
                </div>
                })}



            </div>
            <div className="progresses mt-2">

                {props.ListStep.map(function (data, i) {
                            if(data.SpaceType === true){
                        return <span key={i}  className="linespace secondary"></span>
                    }

                    let className = "steps "
                    if(props.MaxStep > data.Num){
                        className += " steps--valid";
                    }else{
                        className += " steps--pending";
                    }
                   /* if(data.State === false){
                        className += " steps--pending";
                    }
                    if(data.State === true){
                        className += " steps--valid";
                    }*/
                    if(data.Num === props.CurStep){
                        className += " fw-semibold";
                    }else{
                        className += " secondary";

                    }

                    return       <div key={i} className={className + ((data.Num !== props.CurStep && props.MaxStep >= data.Num) ? " LinkActif" : '' )} onClick={function () {
                        if(data.Num !== props.CurStep && props.MaxStep >= data.Num){
                            RedirectToStep(data.Num);
                        }
                    }}>
                        {data.text}
                    </div>
                })}

            </div>

        </div>;
}

export default ProgressStep;

/*
* Example  :
*  <div className="progresses">
{

                <div className="stepsIco">
                    <img src={IcoCheck} width={21} />
                </div>


                <span className="line"></span>

                <div className="stepsIco">
                    <img src={IcoCheck} width={21} />
                </div>


                <span className="lineInProgress"></span>

                <div className="stepsInProgress">
                    <span className="font-weight-bold">3</span>
                </div>

                <span className="lineInProgress"></span>

                <div className="stepsInProgress">
                    <span className="font-weight-bold">4</span>
                </div>

            </div>
            <div className="progresses mt-2">
                <div className="steps steps--valid">Données<br />Personnelles
                </div>
                <span className="linespace"></span>
                <div className="steps steps--valid">
                    Choix Formations
                </div>
                <span className="linespace"></span>
                <div className="steps steps--pending">
                    Documents Justificatifs
                </div>                <span className="linespace"></span>
                <div className="steps steps--pending">
                    Paiement &<br />Validation
                </div>

            </div>
* */