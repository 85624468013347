/*
newCandidature : Généère une nouvelle procédure de dépot d'une candidature.
 */

import {json} from "react-router-dom";

function newCandidature(session, authToken, concoursID, callback){
    let param = [];
    param["AuthenticityToken"] = authToken;
    param["data"] = JSON.stringify({ConcoursID: concoursID});

        session.ReqPost("/api/secure/candidater/new", function (response) {
        return response.json().then(function(data) {
          callback(data);
        });
    }, param)
}

export default newCandidature;