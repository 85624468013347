import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import Button, {TypePrimary} from "../../../bootstrap/button";
import SingleUploader from "./singleUploader";



export default function MultiUploader(props) {
    const InputFileRef = useRef(null);

    const SelectFiles = () => {
        InputFileRef.current.click();
    }

    return <div className="card mt-4 maxWidth m-auto mb-4">
        <div className="card-header">
            {props.Titre}
        </div>
        <div className="card-body p-md-5">
            {props.Description}

            <input type="file" ref={InputFileRef} onChange={(e) => {props.appendFiles(props.TypePieceJointe, e)}} multiple accept="application/pdf" hidden={true}  />
            <ul className="list-group mt-4">
                {props.fileList.map((Ojb, i) => {
                        return <SingleUploader key={i} Data={Ojb} PostPreUpload={props.PostPreUpload} TypePieceJointe={props.TypePieceJointe} UpdateDataFile={props.UpdateDataFile} />;
                    }
                )}
            </ul>
            <div className={"text-center mt-4"}><Button nom={"Ajouter"} type={TypePrimary} clickCallback={SelectFiles} /></div>
        </div>
    </div>;
}