import React, {useEffect} from 'react';
import '../../App.scss';
import { useNavigate, Link } from "react-router-dom";
import {connectAuthEmulator, onAuthStateChanged} from "firebase/auth";



function LogoutPage(props) {
    useEffect(() => {
        if(props.session.IsLoggedIn() === true){
            props.session.Logout(function (bool, error) {
                console.log(bool, error)
            });
        }

        }, [props.session]);

    return (
        <div className="alert alert-info"
             role="alert">
            Vous avez bien été déconnecté.
        </div>


    );
}

export default LogoutPage;
