export default function computeAge(DateNaissace, Date) { // Format AAAA-MM-JJ
    let DateNaissaceAr = DateNaissace.split('-')
    let DateAr = Date.split('-')

    let age = DateAr[0] - DateNaissaceAr[0]; // Année

    if (DateNaissaceAr[1] > DateAr[1]) { // Mois
        age = age - 1
    }

    if (DateNaissaceAr[1] === DateAr[1] && DateNaissaceAr[2] > DateAr[2]) { // Jour
        age = age - 1
    }
    return age;
}