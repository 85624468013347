import {ChangeEvent, useEffect, useRef, useState} from 'react';
import MultiUploader from "./multiuploader";
import Button, {TypePrimary} from "../../../bootstrap/button";

const FilePendingPreAuthorisation = 100;
const FilePendingUpload = 200;
const FileUploading = 300;
const FilePendingConfirmation = 400;
const FileSent = 1000;

function RegisterFormStep3(props) {

    const [errorMsg, seterrorMsg] = useState(null);
    const [fileList, setFileList] = useState([]);

    const ValidateStep3 = (done) => {
        // Est-ce qu'une pièce jointe a été envoyée pour au moins chaque type ?
        let IsOk = true;
        let ListType = ["IDENT", "CV", "LETTRE_MOTIV", "NOTES_BAC", "BULLETIN"];
        for (let i = 0; i < ListType.length; i++) {
            let r = fileList.some(e => e.Type === ListType[i] && e.Etat === FileSent)
            if(r === false){
                IsOk = false;
            }
        }

        if(IsOk === false){
            done();
            seterrorMsg(<>Vous devez obligatoirement nous transmettre tous les documents justificatifs.<br /><strong>Veuillez transmettre les éléments manquants.</strong></>);
            return
        }
        seterrorMsg(null);

        props.PostData({}, 3, (ErrorsMessages) => {
            console.log("Error");
            console.log(ErrorsMessages);
            done();
            // errorcallback(ErrorsMessages);
        });

    }

    const UpdateFileData = (LocalID, NewData) => {
        let newFileList = structuredClone(fileList);
        let index = newFileList.findIndex(item => item.LocalID === LocalID);
        newFileList[index] = NewData;
        setFileList(newFileList);
    }

    useEffect(() => {
        props.GetData("filelist", (data) => {
            console.log(data)
        }, (data) => {
            if(data.List !== null){
                setFileList(data.List);
            }
            console.log(data)
        })

    }, [])

    const randID = () => {
        return performance.now().toString(36) + Math.random().toString(36).substring(2);
    }


    const appendFiles = (TypePieceJointe, e: ChangeEvent) => {
        seterrorMsg(null);
        let ToAppen = [];

        for (let i = 0; i < e.target.files.length; i++) {
            let Ojb = {
                Type: TypePieceJointe,
                LocalID: randID(),
                objFile: e.target.files[i],
                FileName: e.target.files[i].name,
                Etat: FilePendingPreAuthorisation,
            }
            //Upload(Ojb);
            ToAppen.push(Ojb);
        }

        setFileList([...fileList, ...ToAppen]);

    };

    return (
        <>
            <div className="alert mt-4  alert-info maxWidth m-auto"
                 role="alert">
                <p>Tous les documents doivent être transmis au format PDF.</p>
                <p><strong>Une pièce illisible peut entrainer le refus de votre candidature par le jury de concours</strong>.</p>

            </div>

            <MultiUploader fileList={fileList.filter(d => d.Type === "IDENT")} appendFiles={appendFiles} PostPreUpload={props.PostPreUpload} TypePieceJointe={"IDENT"} UpdateDataFile={UpdateFileData} Titre={"Justificatif d'Identité"} Description={"Le document peut être une Carte Nationale d'Identité Française, un Passeport ou un titre de séjour valide. (Le ou les fichiers doivent être en PDF)"} />


            <MultiUploader fileList={fileList.filter(d => d.Type === "CV")} appendFiles={appendFiles} PostPreUpload={props.PostPreUpload} TypePieceJointe={"CV"} UpdateDataFile={UpdateFileData} Titre={"Curriculum Vitae"} Description={"Votre Curriculum Vitae au format PDF."} />

            <MultiUploader fileList={fileList.filter(d => d.Type === "LETTRE_MOTIV")} appendFiles={appendFiles} PostPreUpload={props.PostPreUpload} TypePieceJointe={"LETTRE_MOTIV"} UpdateDataFile={UpdateFileData} Titre={"Lettre de motivation"} Description={"Lettre de motivation dactylographiée au format PDF."} />

            <MultiUploader fileList={fileList.filter(d => d.Type === "NOTES_BAC")} appendFiles={appendFiles} PostPreUpload={props.PostPreUpload} TypePieceJointe={"NOTES_BAC"} UpdateDataFile={UpdateFileData} Titre={"Relevé des notes du baccalauréat"} Description={"Votre relevé de notes du baccalauréat au format PDF."} />

            <MultiUploader fileList={fileList.filter(d => d.Type === "BULLETIN")} appendFiles={appendFiles} PostPreUpload={props.PostPreUpload} TypePieceJointe={"BULLETIN"} UpdateDataFile={UpdateFileData} Titre={"Bulletins scolaires"} Description={"L'intégralité des bulletins scolaires des années suivies dans l'enseignement supérieur. Pour votre année d'étude au niveau BAC+2, vous devez à minima nous transmettre votre bulletin du premier trimestre ou semestre.\n(Le ou les fichiers doivent être en PDF)"} />


            {errorMsg !== null && <div className="alert mt-4 mb-4 alert-danger maxWidth m-auto"
                 role="alert">
                {errorMsg}

            </div>}


            <div className={"text-center"}>
                <Button nom={"Valider"} className={"btn-lg"} type={TypePrimary} disableOnClick={true} spinnerOnClick={true} clickCallback={ValidateStep3} />
            </div>
        </>
    );

}

export default RegisterFormStep3;