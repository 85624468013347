import LogoEcole from "../../logoecole.svg";
import {Link} from "react-router-dom";
import React from "react";
import PathMatch from "../../helper/PathMatch";
import IconLogout from "./IconLogout";

function LayoutMain(props) {

    let curAnnee = new Date().getFullYear()
    if(new Date().getMonth() > 7){
        curAnnee++;
    }

    return <>

        <div className="container py-3">
            <header>
                <div className="d-flex flex-column flex-lg-row align-items-center pb-3 mb-4 border-bottom">
                    <Link to={"/"}
                       className="d-flex align-items-center text-dark text-decoration-none">
                        <img src={LogoEcole} width={152} height={55} alt={"ESITC Paris"}/>
                        <span className="fs-4 ms-3 text-smalluppercase">Concours {curAnnee}</span>
                    </Link>

                    {props.session.IsLoggedIn() === true && // Utilisateur connecté
                        <nav className="d-inline-flex mt-2 mt-lg-0 ms-lg-auto">
                            <Link className={"me-3 py-2 text-decoration-none"+PathMatch("/", " fw-bold")}
                                  to="/">Candidater</Link>
                            <Link className={"me-3 py-2 text-decoration-none"+PathMatch("/mescandidatures", " fw-bold")}
                                  to="/mescandidatures">Mon espace</Link>
                            <Link to={"/logout"} className={"py-2 text-decoration-none"}><IconLogout /><span className={"hidesmall"}> ({props.session.DisplayUser()})</span></Link>
                        </nav> }

                    {props.session.IsLoggedIn() === false && // Utilisateur déconnecté
                    <nav className="d-inline-flex mt-2 mt-lg-0 ms-lg-auto">
                        <Link className={"me-3 py-2 text-decoration-none"+PathMatch("/", " fw-bold")}
                              to="/">Candidater</Link>
                        <Link className={"me-3 py-2 text-decoration-none"+PathMatch("/register", " fw-bold")}
                              to="/register">Créer un compte</Link>
                        <Link className={"py-2 text-decoration-none"+PathMatch("/login", " fw-bold")}
                              to="/login">Se connecter</Link>
                    </nav> }
                </div>


            </header>

            <main>
                {props.children}

            </main>
        </div>
        </>;
}

export default LayoutMain;