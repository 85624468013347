import Button, {TypePrimary} from "../../../bootstrap/button";
import "./style.scss"
import IcoPaiment from "./credit_card.svg"
import {useEffect, useState} from "react";
function RegisterFormStep4(props) {

    const [errorMsg, setErrorMsg] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);

    const [point1, setPoint1] = useState(false);
    const [point2, setPoint2] = useState(false);
    const [point3, setPoint3] = useState(false);


    const [montant, setMontant] = useState("Chargement...");

    const CheckOut = (done) => {
        if(point1 === false || point2 === false || point3 === false){

            setErrorMsg("Vous devez accepter l'intégralité des éléments précédents avant de pouvoir procéder au paiement.");
            done();
            return;
        }
        setInfoMsg("Vous allez être redirigé(e) vers la page de paiement dans quelques instants.");
        props.PostPreUpload({}, (erreur) => {
            console.log(erreur);
            setInfoMsg(null)
            done();
        }, (data) => {
            window.location.href = data.URL;
            //done();

            console.log(data);
        }, "payment")


    }

    useEffect(() => {
        let Montant = 0;
        let Nbre = 0;
        let NbreMax = 0;
        console.log(props.candidature);
        for (var i = 0; i < props.candidature.Formations.length; i++) {
            NbreMax++;
            let r  = props.candidature.Formations[i].CampusList.some(function(e, index) {
                return e.IsCandidat === true;
            });
            if(r){
                Montant = Montant + props.candidature.Formations[i].CoutIndiv
                Nbre = Nbre +1;
            }
        }

        // 60 Euro pour cursus Etudiant
        // 90 Euro pour le Cursurs Apprenti
        // 120 pour les deux
        if(Nbre === NbreMax){
            Montant = props.candidature.CoutAllFormation;
        }
        setMontant(Montant);

    }, [])

    return (
        <>

            <div className="card mt-4 maxWidth m-auto mb-4">
                <div className="card-header">
                    Paiement & Validation
                </div>
                <div className="card-body p-md-5">

                    <p>Veuillez lire attentivement puis accepter les points suivants :</p>



                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               role="switch"
                               id="point1"
                               checked={point1}
                               onChange={() => {
                                   setPoint1(!point1)
                                   setErrorMsg(null);
                               }}
                        />
                            <label className="form-check-label prevent-select"
                                   htmlFor="point1" style={{cursor: "pointer"}}>J'accepte sans réserve le règlement de concours et certifie de l'exactitude des renseignements communiqués.</label>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               role="switch"
                               id="point2"
                               checked={point2}
                               onChange={() => {
                                   setPoint2(!point2)
                                   setErrorMsg(null);
                               }}
                        />
                        <label className="form-check-label prevent-select"
                               htmlFor="point2" style={{cursor: "pointer"}}>J'ai bien pris note que les frais de candidature ne sont pas remboursables et ceci quel que soit le déroulement de la candidature : non admissible, non admis, absence aux épreuves de sélection, désistement...</label>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               role="switch"
                               id="point3"
                               checked={point3}
                               onChange={() => {
                                   setPoint3(!point3)
                                   setErrorMsg(null);
                               }}
                        />
                        <label className="form-check-label prevent-select"
                               htmlFor="point3" style={{cursor: "pointer"}}>J'ai bien pris note que le paiement des frais de concours entraine la finalisation de mon dossier de candidature. Mon dossier ne sera donc plus modifiable, il sera transmis en l'état au jury de concours.</label>
                    </div>
                    {errorMsg !== null && <div className="alert mt-4 mb-4 alert-danger maxWidth m-auto"
                                               role="alert">
                        {errorMsg}

                    </div>}
                    <div className={"text-center mt-5 lead"}>Le montant des frais de concours s'élève à <strong>{montant} €</strong></div>
                    <div className={"text-center"}>

                        <Button nom={<><img src={IcoPaiment} /> Procéder au paiement</>} className={"btn-lg mt-3"} type={TypePrimary} disableOnClick={true} spinnerOnClick={true} clickCallback={CheckOut} />
                    </div>

                    {props.paiementError !== undefined && props.paiementError === true && <div className="alert mt-4 mb-4 alert-warning maxWidth m-auto text-center"
                                                    role="alert">
                        <strong>Le paiement a échoué, nous n'avons pas reçu de confirmation de la part de votre banque.</strong><br />Veuillez recommencer.

                    </div>}
                    {infoMsg !== null && <div className="alert mt-4 mb-4 alert-info maxWidth m-auto"
                                               role="alert">
                        {infoMsg}

                    </div>}

                </div>
            </div>



        </>

    );
}

export default RegisterFormStep4;