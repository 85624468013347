import React, {useEffect} from 'react';
import '../../App.scss';
import LogoEcole from "../../logoecole.png";
import {useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "../../bootstrap/modal";
import Button, {TypeSecondary, TypePrimary} from "../../bootstrap/button";
import PathMatch from "../../helper/PathMatch";
import newCandidature from "../../candidature/postuler";
import SendConfirmLink from "../../firebase/SendMail";



function HomePage(props) {
    const [concoursList, setConcoursList] = useState(null);
    const [concoursError, setConcoursError] = useState(null);

    const navigate = useNavigate();

    const [messageModalVisible, setMessageModalVisible] = useState(null);
    const [NotLoggedModalVisible, setNotLoggedModalVisible] = useState(false);

    const Postuler = (data, callbackEndBtn) => {
        if(data.IsOpen === false){
            setMessageModalVisible({titre: "Phase principale terminée", content: <><div className={"text-center mb-3"}><strong>La phase principale de ce concours est terminée.</strong></div>Il est actuellement impossible de déposer une candidature. Une phase complémentaire aura peut-être lieu prochainement, nous vous invitons à revenir régulièrement sur cette page.</>});
            callbackEndBtn();
            return;
        }

        if(props.session.IsLoggedIn() === false){
            setNotLoggedModalVisible(true);
            console.log("End")
            callbackEndBtn();
            return
        }


            props.session.GetUserIdToken().then(idToken => {
                newCandidature(props.session, idToken, data.ID, function (json) {
                    console.log(json);
                    if(json.status === "OK"){
                        navigate("/mescandidatures/"+json.data.ConcoursID+"/"+json.data.CandidatureID);
                        return;
                    }
                    console.log("End")
                    // Error Todo
                    alert("Error");
                })
            });
            


    }

    useEffect(() => {
        props.session.ReqGet("/api/concours/list", async function (resp) {
            const json = await resp.json();
            if(json.status === "OK"){
                if(json.data === null){
                    setConcoursList([]);
                    return;
                }
                setConcoursList(json.data);
            }else{
                setConcoursError("BADREQUEST")
            }

            //console.log(json.data);
        })
    },[]);
    
    return (
<>
    {messageModalVisible !== null &&
        <Modal param={{
            Titre: messageModalVisible.titre,
            Content: messageModalVisible.content,
            CloseCrossCallBack: function (e) {
                setMessageModalVisible(null);
            },
            BottomBtns: [<Button type={TypeSecondary} key={0} clickCallback={function () {
                setMessageModalVisible(null);
            }} nom={"Retour"} />],


        }} /> }
    {NotLoggedModalVisible === true &&
    <Modal param={{
        Titre: "Non connecté",
        Content: <>
            <p>Veuillez créer un compte puis vous connecter afin de pouvoir candidater aux concours.</p>
            <div className="text-center">
                <Button clickCallback={function () {
                    navigate("/register");
            }}
                        className={"me-2"}
                        type={TypePrimary}
                        nom={"Créer un compte"} />
                <Button clickCallback={function () {
                    navigate("/login");
                }}
                        className={"ms-2"}
                        type={TypePrimary}
                        nom={"Me connecter"} />
            </div>

        </>,
        CloseCrossCallBack: function (e) {
            setNotLoggedModalVisible(false);
        },
        BottomBtns: [<Button type={TypeSecondary} key={0} clickCallback={function () {
            setNotLoggedModalVisible(false);
        }} nom={"Retour"} />],


    }} /> }
    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">

        <p className="fs-5 text-muted">Pour les concours du cycle préparatoire, <a href={"https://www.esitc-paris.fr/fr/admissions"}  className={"text-decoration-none"}>cliquez ici</a>.</p>
    </div>
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mb-4 text-center  justify-content-md-center">


        {concoursError != null && <div className="alert alert-light"
                                       role="alert">
            Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet.
        </div>
        }

            {concoursList === null && <div className="alert alert-light"
                                           role="alert">
                Chargement en cours...
            </div>
            }
            {concoursList !== null && concoursList.length === 0 && <div className="alert alert-info"
                                                                        role="alert">

                Les concours sont actuellement clos.
            </div>
            }
                {concoursList !== null && concoursList.map(function (data, i) {
                return <div className="col" key={i}><div className="card mb-3 rounded-3 shadow-sm border-primary">
                    <div className="card-header py-3 text-bg-primary border-primary">
                        <h4 className="my-0 fw-normal" dangerouslySetInnerHTML={{ __html: data.Titre }}></h4>
                    </div>
                    <div className="card-body">
                        <div dangerouslySetInnerHTML={{ __html: data.Texte }}></div>
                        {data.IsOpen === false && data.EnPhaseCompl === false && <p className={"lead"} style={{color: "red", fontWeight: "bold", fontVariantCaps: "small-caps"}}>phase principale terminée</p> }
                        {data.IsOpen === false && data.EnPhaseCompl === true && <p className={"lead"} style={{color: "red", fontWeight: "bold", fontVariantCaps: "small-caps"}}>concours terminé</p> }
                        {data.IsOpen === true && data.EnPhaseCompl === true && <p className={"lead"} style={{fontWeight: "bold", fontVariantCaps: "small-caps"}}><span style={{color: "red"}}>phase principale terminée</span><br /><span style={{color: "#05bfa1"}}>phase complémentaire Ouverte</span></p> }
                        { !(data.IsOpen === false && data.EnPhaseCompl === true) && <Button nom={"Candidater"} spinnerOnClick={true} disableOnClick={true} className={"btn-lg w-100"} type={data.IsOpen === false ? TypeSecondary : TypePrimary} clickCallback={(callbackEnd) => Postuler(data, callbackEnd)}/> }

                    </div>
                </div></div>
            })



            }




    </div>
    </>
    );
}

export default HomePage;
