import React, {useEffect, useState} from 'react';
import '../../App.scss';
import Button, {TypePrimary} from "../../bootstrap/button";
import {useNavigate} from "react-router-dom";
import CandidatureItem from "./candidatureItem";


function CandidaturesHomePage(props) {
    const [CandidaturesList, setCandidaturesList] = useState(undefined)
    const navigate = useNavigate();

    useEffect(() => {
        if(props.session.sessionState === "Loading"){
            console.log("Session en cours d'initialisation.")
            return;
        }

        if (props.session.IsLoggedIn() === false) {
            navigate("/")
            return;
        }

        props.session.GetUserIdToken().then(authToken => {
            let param = [];
            param["AuthenticityToken"] = authToken;

            props.session.ReqGet("/api/secure/candidater/list", function (response) {
                return response.json().then(function(json) {
                    if(json.status !== "OK"){
                        // Erreur à gérer my dear !!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        return
                    }
                    setCandidaturesList(json.data.List);
                   // console.log(json.data)
                });
            }, param)
        });

    }, [props.session.sessionState])

    const MoveToHomePage = () => {
        navigate("/");
    }

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                <h2 className={"mb-3"}>Mes candidatures</h2>
                <div className={"mb-3 mt-3"}>
                    {CandidaturesList === undefined && <i>Chargement...</i>}
                    {CandidaturesList !== undefined && CandidaturesList === null && <i>Vous n'avez pas encore candidaté.</i>}

                    <ul className="list-group">
                    {CandidaturesList !== undefined && CandidaturesList !== null && CandidaturesList.map(function (data, i) {
                        return <CandidatureItem key={i} data={data} session={props.session}/>
                    })}
                    </ul>
                </div>
                {CandidaturesList !== undefined && CandidaturesList === null &&
                <div className={"text-center"}>
                <Button nom={"Liste des concours"} type={TypePrimary} clickCallback={MoveToHomePage}  />
                </div>}
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default CandidaturesHomePage;
