import React, {useEffect, useState} from 'react';
import '../../App.scss';
import Button, {TypePrimary} from "../../bootstrap/button";
import {useNavigate} from "react-router-dom";


function CandidatureItem(props) {
    const navigate = useNavigate();
    const [NomConcours, setNomConcours] = useState(<i><small>Chargement...</small></i>)

    useEffect(() => {
        props.session.ReqGet("/api/concours/"+props.data.ConcoursId, async function (resp) {
            const json = await resp.json();
            if(json.status === "OK"){
                setNomConcours(json.data.Titre);
            }
            //console.log(json.data);
        })

    }, [])

    const Consulter = () => {
        navigate("/mescandidatures/"+props.data.ConcoursId+"/"+props.data.CandidatureId)
    }

    return (
        <li className="list-group-item d-flex justify-content-between align-items-center"><span dangerouslySetInnerHTML={{ __html: NomConcours}}></span>
                                <span><button type="button"
                                              className="btn btn-primary btn-sm" onClick={Consulter}>Consulter</button></span>
                            </li>

    );
}

export default CandidatureItem;
