import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "./singleUploader.scss"

import IconDelete from "./delete.svg"
import IconFile from "./file.svg"

const FilePendingPreAuthorisation = 100;
const FilePendingUpload = 200;
const FileUploading = 300;
const FilePendingConfirmation = 400;
const FileSent = 1000;
const FilePendingDelete = 4500;
const FileDelete = 5000;
const FileIsNotPDF = 9999;

export default function SingleUploader(props) {

    const [IsUploading, setIsUploading] = useState(false);
    const [IsDownlading, setIsUDownlading] = useState(false);

    let Data = props.Data;
    let setData = props.UpdateDataFile;

    const DownloadFile = (Obj) => {
        if(IsDownlading === true){
            return
        }
        setIsUDownlading(true);
        props.PostPreUpload({ID: Data.ID*1}, () => {
            // Todo Error

        }, (url) => {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = Data.FileName;
                    link.click();
                    setIsUDownlading(false);
                })

        }, "download");
    }

    const DeleteFile = () => {
       let r =  window.confirm("Souhaitez-vous vraiment supprimer le fichier "+Data.FileName+ " ?")
        console.log(r, Data.ID);
        if(r && Data.ID !== undefined && Data.ID !== null){
            UpdateEtat(FilePendingDelete);
            props.PostPreUpload({ID: Data.ID*1}, () => {
                // Todo Error

            }, () => {
                UpdateEtat(FileDelete);
            }, "delete");
        }
    }

    const UpdateEtat = (NewEtat) => {
        Data.Etat = NewEtat;
        props.UpdateDataFile(Data);
    }

    const TrigUpload = () => {

        if(Data.FileName.toLowerCase().slice(-4) !== ".pdf"){
            setIsUploading(false);
            Data.Etat = FileIsNotPDF;
            props.UpdateDataFile(Data);

            return
        }


        let PreUploadData = {
            TypePieceJointe: props.TypePieceJointe,
            FileName: Data.FileName,
        }

        props.PostPreUpload(PreUploadData, () => {
            // Todo Error

        }, (Predata) => {
            setIsUploading(true);
            //UpdateEtatWithID(FileUploading, Predata.ID);

            let Formdata = new FormData()

            for (const [key, value] of Object.entries(Predata.Headers)) {
                Formdata.append(key, value)
            }

            Formdata.append('file', Data.objFile)

            fetch( Predata.URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'omit',
                body: Formdata
            }).then((data) => {
                //UpdateEtatWithID(FilePendingConfirmation, Predata.ID);

                // Vérification que le fichier est bien présent
                props.PostPreUpload({ID: Predata.ID*1}, () => {
                    // Todo Error

                }, (data) => {
                    setIsUploading(false);
                    Data.Size = data.Size;
                    Data.Etat = FileSent;
                    Data.ID = Predata.ID;
                    props.UpdateDataFile(Data);
                }, "confirm");

                console.log('Success:', data);
            })
                .catch((error) => {
                    console.error('Error:', error);
                });



        }, "filemeta");
    }

    useEffect(() => {
        if(FilePendingPreAuthorisation === Data.Etat){
            TrigUpload();
        }
    }, []);


    if(Data.Etat === FileIsNotPDF){
        return <li className="list-group-item d-block justify-content-between align-items-start"  style={{color: "gray"}}>
            <div className={"m-auto"}>
                <div  className={"ms-2 me-auto text-truncate"} style={{textDecoration: "line-through"}}>
                     {Data.FileName}
                </div>
                <span className="ms-2 text-danger">Le fichier doit obligatoirement être un PDF. (Votre fichier a été supprimé)</span>
            </div>

        </li>;
    }

    if(Data.Etat === FileDelete || Data.Etat === FilePendingDelete){
        return <li className="list-group-item d-flex justify-content-between align-items-center"  style={{color: "gray"}}>

                 <span className={"ms-2 me-auto text-truncate"}  style={{textDecoration: "line-through"}}>
                     {Data.FileName}
                 </span>
            <span>{Data.Etat === FileDelete && <i>supprimé</i>}{Data.Etat === FilePendingDelete && <div className="spinner-border text-primary spinner-border-sm ms-2"
                                                                                                        role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}</span>
        </li>;
    }


//style={{visibility: "hidden"}}
    return (

             <li className="list-group-item d-flex justify-content-between align-items-center">

                 <span className={"ms-2 me-auto text-truncate"} onClick={() => { DownloadFile(); } }>
                     <span className={((Data.Etat === FileSent) ? "filenameValid" :"")} ><img src={IconFile} alt={""} height={25} className={"pb-1"}/> {Data.FileName}</span> {Data.Etat === FileSent && formatBytes(Data.Size, 0)} {IsDownlading === true && <div className="spinner-border text-primary spinner-border-sm ms-2"
                                                                                                                                                                                                          role="status">
                     <span className="visually-hidden">Téléchargement...</span>
                 </div>}
                 </span>
                 <span>{Data.Etat === FileSent && <img src={IconDelete} className={"DeleteBtn"} alt={"Supprimer"} onClick={() => { DeleteFile(); }} height={25} />}


                                {Data.Etat !== FileSent && <div className="spinner-border text-primary spinner-border-sm ms-2"
                                                                role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>} {IsUploading === true && <small><i>Envoi..</i></small>}
                            </span>
             </li>

    );

}

function formatBytes(bytes,decimals) {
    if(bytes === 0){
        return '0 Bytes';
    }
    let k = 1024,
        sizes = ['o', 'ko', 'Mo', 'Go', 'To'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
}
