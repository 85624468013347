
/*BTS
   • Bâtiment
   • TP
- DUT/BUT Génie Civil Construction Durable
- Conducteurs de Travaux ESTP
* CB
* CTP
* CAB
* CATP
- Licence Génie Civil
*/
import {useState} from "react";
import SelectInput from "../../../helper/components/inputs/SelectInput";
import Button, {TypeSecondary} from "../../../bootstrap/button";

import DeleteBTN from "../../edit.svg"
import TextInput from "../../../helper/components/inputs/TextInput";
import RadioInput from "../../../helper/components/inputs/RadioInput";


function SituationApprenti(props) {

    const UpdateData = (event) => {
        props.nullError(null);
        props.notifUpdate();
        props.setIsApprenti(event.target.value)
    }

   return <div className={"mt-2"}><hr />
       <div className={"text-center mb-3"}><strong>Êtes-vous actuellement en formation sous statut d'apprenti ?</strong></div>
       <RadioInput name={"IsApprenti"} label={"Statut d'apprenti"} type={"radio"} setInputs={UpdateData}  value={props.value} options={[{ value: 'Oui', name: 'Oui' },
       { value: 'Non', name: 'Non' }]}  />
       {props.error !== null && <div className="alert alert-danger"
                                     role="alert">
           {props.error}
       </div>}
    </div>;


}


export default SituationApprenti;