import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './App.scss';
import SessionContext from "./firebase/session";

import HomePage from "./pages/home/home";
import {useContext} from "react";
import React from "react";
import LayoutMain from "./pages/layouts/main";
import Register from "./pages/register/register";
import Login from "./pages/login/login";
import Logout from "./pages/logout/logout";
import MailConfirm from "./pages/register/mailConfirm";
import MailConfirmAfterLoginPage from "./pages/login/mailConfirm";
import MailConfirmResultPage from "./pages/register/mailConfirmResult";
import ResetPasswordPage from "./pages/login/resetPassword";
import ResetPasswordStep2Page from "./pages/login/resetPasswordStep2";
import CandidaturesHomePage from "./pages/candidatures/CandidaturesHomePage";
import CandidatureDispatch from "./candidature/dispatch";




function App() {
    let S = useContext(SessionContext)
    return (
        <>

        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<LayoutMain session={S} ><HomePage session={S} /></LayoutMain>}/>

                <Route exact path="/register" element={<LayoutMain session={S} ><Register session={S} /></LayoutMain>}/>
                <Route exact path="/register/mail-confirm" element={<LayoutMain session={S} ><MailConfirm session={S} /></LayoutMain>}/>
                <Route exact path="/register/confirm/:Id/:Token" element={<LayoutMain session={S} ><MailConfirmResultPage session={S} /></LayoutMain>}/>

                <Route exact path="/login" element={<LayoutMain session={S} ><Login session={S} /></LayoutMain>}/>
                <Route exact path="/login/reset-password" element={<LayoutMain session={S} ><ResetPasswordPage session={S} /></LayoutMain>}/>
                <Route exact path="/login/reset-password/:Id/:Token" element={<LayoutMain session={S} ><ResetPasswordStep2Page session={S} /></LayoutMain>}/>
                <Route exact path="/login/mail-confirm" element={<LayoutMain session={S} ><MailConfirmAfterLoginPage session={S} /></LayoutMain>}/>

                <Route exact path="/mescandidatures" element={<LayoutMain session={S} ><CandidaturesHomePage session={S} /></LayoutMain>}/>
                <Route exact path="/mescandidatures/:ConcoursId/:CandidatureId" element={<LayoutMain session={S} ><CandidatureDispatch session={S} /></LayoutMain>}/>
                <Route exact path="/mescandidatures/:ConcoursId/:CandidatureId/:Param" element={<LayoutMain session={S} ><CandidatureDispatch session={S} /></LayoutMain>}/>


                <Route exact path="/logout" element={<LayoutMain session={S} ><Logout session={S} /></LayoutMain>}/>
            </Routes>
        </BrowserRouter>

        </>
    ); // MailConfirmAfterLoginPage
}

export default App;
/*<Route path="about" element={<About />} />
                <Route path="*" element={<NoMatch />} />*/