import React from 'react';

const BtnSubmit = ({ btnName, btnLoading, submitCallback, IsLoading }) => {
    if(IsLoading === true){
        return (
            <button className="btn btn-primary"
                    type="submit"
                    disabled>
                  <span className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true" />&nbsp;&nbsp;{btnLoading}
            </button>
        )
    }
    return (
            <button type="submit"
            className="btn btn-primary" onClick={submitCallback}>{btnName}
    </button>
    )/*
    return (
        <div>
            <input id={name} name={name} type={type} value={value} checked={value || false} onChange={setInputs} className={"uk-checkbox"} />
            <label htmlFor={name} className="uk-form-label uk-margin-small-left">{label}</label>
            <ValidationAlert content={alert} />
        </div>
    )

    <button className="btn btn-primary"
                type="button"
                disabled>
                  <span className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"></span>
            Loading...
        </button>

    */
}

export default BtnSubmit;