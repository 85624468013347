import * as React from 'react';
import ValidationAlert from '../ValidationAlert';

function TextInput({ name, label, type, value, alert, setInputs, isPassword, isDisable, isDate, HelpText, IsMandatory}) {
    let InputClass = "form-control"
    if (typeof alert !== 'undefined' &&  alert != null && alert.length > 0) {
        InputClass = "form-control is-invalid"
    }else if (typeof alert !== 'undefined' &&  alert != null && alert === true) {
        InputClass = "form-control is-valid"
    }
    type = (isPassword === true) ? "password" : type;
    type = (isDate === true) ? "date" : type;

    let Disabled = false;
    if (typeof isDisable !== 'undefined' &&  isDisable === true) {
        Disabled = true;
    }

    if (typeof isDisable !== 'undefined' &&  isDisable === true) {
        Disabled = true;
    }


    return (
        <div className="mb-3">
            <label htmlFor={name}
                   className="form-label">{label}{IsMandatory === true && " (*)"}</label>
            <input type={type}
                   className={InputClass}
                   id={name}
                   name={name}
                   value={value || ""} onChange={setInputs}
                   disabled={Disabled}
                   />
            {HelpText !== undefined && <div className="form-text">{HelpText}</div>}

            <ValidationAlert content={alert} />
        </div>
    )
}

export default TextInput;