import React from 'react';
import '../../App.scss';
import Input, {TypeEmail, TypePassword} from "../../bootstrap/input";
import {useState} from "react";
import UserWithEmailPasswdGetTextByCode from "../../firebase/UserWithEmailAndPassword_errors";
import {useParams} from "react-router-dom";

const loginModelDefault = [{
    name: "passwdRegister",
    label: "Mot de passe",
    type: TypePassword,
},{
    name: "passwdConfirmRegister",
    label: "Confirmer votre mot de passe",
    type: TypePassword,
}
];
function ResetPasswordStep2Page(props) {
    const { Id, Token } = useParams();
    const [Loading, setLoading] = useState(false);
    const [Model, setModel] = useState(loginModelDefault);
    const [ErrorMessage, setErrorMessage] = useState(null);
    const [FinalStatus, setFinalStatus] = useState(null);

    const onChange = (name, newValue) => {
        if(ErrorMessage !== null){
            setErrorMessage(null);
        }

        if(FinalStatus !== null){
            setFinalStatus(null);
        }

        let NewModel = structuredClone(Model);
        let objIndex = NewModel.findIndex((obj => obj.name === name));
        NewModel[objIndex].value = newValue;
        setModel(NewModel);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(FinalStatus === "OK"){
            return;
        }

        let param = [];
        let objIndex = Model.findIndex((obj => obj.name === "passwdRegister"));
        let objIndexConfirm = Model.findIndex((obj => obj.name === "passwdConfirmRegister"));

        if((Model[objIndex].value !== undefined && (Model[objIndex].value === ""|| Model[objIndex].value.length < 6)) || Model[objIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/weak-password'));
            return;
        }

        if((Model[objIndexConfirm].value !== undefined && (Model[objIndexConfirm].value === "" || Model[objIndexConfirm].value !== Model[objIndex].value)) || Model[objIndexConfirm].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('passwordMismatch'));
            return;
        }

        param["data"] = JSON.stringify({ID: Id, Token: Token, NewPwd: Model[objIndex].value});

        if(Loading === true){
            return;
        }
        setLoading(true);



        props.session.ReqPost("/api/reset-password/confirm", function (response) {
            return response.text().then(function(text) {
                setLoading(false);
                if(text === "OK"){
                    setFinalStatus("OK");
                }else{
                    setFinalStatus("NOK");
                }
            });
        }, param)
    }

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                <h2>Réinitialiser votre mot de passe</h2>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    {Model.map(input => Input(input, ErrorMessage !== null, onChange))}

                    {ErrorMessage !== null && <div className="alert alert-danger"
                                                   role="alert">
                        {ErrorMessage}
                    </div> }

                    <div id="emailHelp"
                         className="form-text mb-3">Veuillez définir votre nouveau mot de passe.
                    </div>

                    { FinalStatus !== null && FinalStatus === "OK" && <div className="alert alert-success"
                                                                            role="alert">
                        <strong>Votre mot de passe a bien été modifié.</strong> <small>Vous pouvez maintenant vous connecter.</small>
                    </div> }


                    { FinalStatus !== null && FinalStatus === "NOK" && <div className="alert alert-danger"
                         role="alert">
                        Une erreur s'est produite. Nous ne sommes pas parvenus réinitialiser votre compte.<br /><br />
                        Les causes pouvant générer cette erreur :<br />
                        - Votre lien a expiré.<br />
                        - Votre nouveau mot de passe est trop court. (6 min).
                    </div> }


                    {Loading === false &&
                        <button type="submit"
                                className="btn btn-primary">Modifier mon mot de passe
                        </button>}
                    {Loading === true &&
                        <button className="btn btn-primary"
                                type="button"
                                disabled>
                        <span className="spinner-grow spinner-grow-sm me-1"
                              role="status"
                              aria-hidden="true"></span>
                            Chargement...
                        </button>}

                </form>
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default ResetPasswordStep2Page;
