import { checkAtLeastLength, checkIsfilled } from "../../../helper/form/inputValidator";
import { parseOnlyNumberSpaceAndPlus} from "../../../helper/form/inputParser";

const ModeleStep1 = [
    {
        name: 'Civilite',
        label: 'Civilité',
        type: 'radio',
        IsMandatory: true,
        //parseFun: parseOnlyUTF8Printable,
        options: [
            { value: 'Mme', name: 'Mme' },
            { value: 'M.', name: 'M.' },
        ],
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
    name: 'Nom',
    label: 'Nom',
        IsMandatory: true,
    type: 'text',
    //parseFun: parseOnlyLetterAndSpaceTiret,
    validators: [{
        //  id: 'email-required',
        isValidFun: checkIsfilled,
        alert: 'Ce champ est obligatoire.'
    }]
},{
        name: 'Prenom',
        label: 'Prénom',
        type: 'text',
        IsMandatory: true,
        HelpText: "Veuillez préciser uniquement votre premier prénom.",
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Veuillez indiquer votre premier prénom.'
        }]
    },{
        name: 'DateNaissance',
        label: 'Date de naissance',
        type: 'text',
        isDate: true,
        IsMandatory: true,
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Vous devez indiquer votre date de naissance.'
        }]
    },{
        name: 'Nationalite',
        label: 'Nationalité',
        type: 'text',
        HelpText: "Exemple: Française",
        IsMandatory: true,
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
        name: 'Adresse',
        label: 'Adresse',
        type: 'text',
        //parseFun: parseOnlyUTF8Printable,
        validators: []
    },{
        name: 'CodePostal',
        label: 'Code postal',
        IsMandatory: true,
        type: 'text',
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
        name: 'Commune',
        label: 'Commune',
        HelpText: "Vous devez préciser votre commune de résidence au moment du dépôt de votre candidature.",
        type: 'text',
        IsMandatory: true,
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
        name: 'Pays',
        label: 'Pays',
        HelpText: "Vous devez préciser votre pays de résidence au moment du dépôt de votre candidature.",
        type: 'text',
        IsMandatory: true,
        //parseFun: parseOnlyUTF8Printable,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
        name: 'TelPortable',
        label: 'Téléphone Portable',
        type: 'text',
        IsMandatory: true,
        parseFun: parseOnlyNumberSpaceAndPlus,
        validators: [{
            //  id: 'email-required',
            isValidFun: checkIsfilled,
            alert: 'Ce champ est obligatoire.'
        }]
    },{
        name: 'Email',
        label: 'Email',
        isDisable: true,
        HelpText: "L'adresse utilisée pour la création de votre compte est obligatoirement utilisée.",
        type: 'text',
        //parseFun: parseOnlyUTF8Printable,
        validators: []
    }
];

export default ModeleStep1;