import React from 'react';

const TypeEmail = "email";
const TypePassword = "password";

function Input(param, IsInvalid, callback) {

    return (
        <div className="mb-3" key={param.name} >
            <label htmlFor={param.name}
                   className="form-label">{param.label}</label>
            <input type={param.type}
                   className={"form-control" + ((IsInvalid === true) ? ' is-invalid' : '')}
                   id={param.name}
                   name={param.name}
                   value={param.value || ""}
                   onChange={function (e) {
                       e.persist();
                       callback(param.name, e.target.value);
                   }}
                   aria-describedby={param.name+"Help"} />
            {param.helper !== undefined &&
            <div id={param.name+"Help"}
                 className="form-text">{param.helper}
            </div> }
        </div>
        /*
        <button type="button"
                className={"btn btn-"+param.type+((param.className !== undefined) ?  " " + param.className : '')} onClick={onclick}>{param.nom}</button>*/
    );
}
export { TypeEmail, TypePassword};
export default Input;
