import React, {useState} from 'react';
import '../../App.scss';
import {useLocation, useNavigate} from "react-router-dom";


function MailConfirmPage(props) {

    const location = useLocation();

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                <div className="alert alert-success"
                     role="alert">
                    Félicitations ! <strong>Votre compte est bien créé</strong> mais il n'est pas encore activé.
                </div>
                <div className="alert alert-warning"
                     role="alert">
                    Vous devez maintenant <strong>confirmer la validité de votre adresse mail</strong>.
                </div>
                { location.state !== null && location.state.EmailReg !== null && <div className="alert alert-info"
                         role="alert">
                         Un mail contenant un lien d'activation vous a été envoyé à l'adresse suivante : <div className={"text-center"}><strong>{location.state.EmailReg}</strong></div>


                </div> }
                <div className="alert alert-light"
                     role="alert">
                    NOTA : Penser à vérifier vos spams.
                </div>
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default MailConfirmPage;
