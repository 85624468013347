import '../../App.scss';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Button, {TypePrimary} from "../../bootstrap/button";
import React from "react";


function MailConfirmResultPage(props) {
    const navigate = useNavigate();
    const Loading = "LOADING";
    const Valid = "OK";
    const Error = "NOK";
    const [confirmed, setConfirmed] = useState(Loading);

    const { Id, Token } = useParams();

    useEffect(() => {
        // Récupération des paramétres pour activation du compte
        props.session.ReqGet("/api/token-confirm/mailconfirm/"+Id+"/"+Token, function (response) {
            return response.text().then(function(text) {
                console.log(text);
                if(text === "OK"){
                    setConfirmed(Valid)
                }else{
                    setConfirmed(Valid)
                }
            });
        })

    }, []);
    
    const ToLoginPage = () => {
        navigate("/login");
    }

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                { confirmed === Loading && <div className="alert alert-info"
                     role="alert">
                    <div className="spinner-grow spinner-grow-sm me-3"
                         role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    Veuillez patienter, les vérifications sont en cours...
                </div> }

                { confirmed === Valid && <><div className="alert alert-success"
                                                role="alert">
                    <strong>Votre compte a bien été activé !</strong><br /> Vous pouvez maintenant vous connecter pour candidater aux concours.
                    <div className={"text-center"}><Button type={TypePrimary} nom={"Me connecter"} clickCallback={ToLoginPage}></Button></div>
                </div>


                </> }

                { confirmed === Error && <div className="alert alert-danger"
                                              role="alert">
                    <strong>Nous ne sommes pas parvenus à activer votre compte.</strong> <br />Votre compte est peut-être déjà activé ou votre lien est invalide.
                    <br /><br />
                        Si le problème persiste, veuillez contacter le support informatique de l'ESITC Paris à informatique@esitc-paris.fr
                </div> }
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default MailConfirmResultPage;
