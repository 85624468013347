import modeleStep1 from "./modeleStep2";
import FormPanel from "../../../helper/components/FormPanel";
import "./style.scss"
import SituationActuelle from "./situationActuelle";
import SituationApprenti from "./ActuellementApprenti";
import {useEffect, useState} from "react";
import Button, {TypePrimary} from "../../../bootstrap/button";
import curFormation from "./curFormation";
import CheckBoxFormation from "./CheckBoxFormation";
import computeAge from "../../../helper/computeAge/computeAge";
function RegisterFormStep2(props) {
    let OneDisable = false;

    const [situationValidated, setSituationValidated] = useState(false);

    const [selectedFormation, setSelectedFormation] = useState(props.candidature.Formations);
    const [errorSelectedFormation, seterrorSelectedFormation] = useState(null);

    const [IsApprenti, setIsApprenti] = useState(null);
    const [errorApprenti, seterrorApprenti] = useState(null);
    const [errorSituAct, seterrorSituAct] = useState(null);

    console.log(props.candidature.DiplomeActuel);
    console.log(props.candidature.DiplomeActuelAutre);
    const [choix, setChoix] = useState(null);
    const [AutreTxt, setAutreTxt] = useState("");

    const notifUpdate = () => {
        if(situationValidated === true){
            setSituationValidated(false);
        }
    }

    useEffect(() => {
        let IsValidated = 0;
        if(AutreTxt === "" && choix === null){
            if(props.candidature.DiplomeActuelAutre !== undefined && props.candidature.DiplomeActuelAutre !== ""){

                let indexForm = curFormation.findIndex(object => {
                    return object.nom === "Autre";
                });
                setChoix([{index: indexForm, value: "Autre"}])
                setAutreTxt(props.candidature.DiplomeActuelAutre);
                IsValidated++;
            }else if (props.candidature.DiplomeActuel !== ""){
                let indexForm = curFormation.findIndex(object => {
                    return object.nom === props.candidature.DiplomeActuel.Nom;
                });

                if (indexForm === -1){
                    return;
                }

                let indexOption = curFormation[indexForm].options.findIndex(object => {
                    return object === props.candidature.DiplomeActuel.Options[0];
                });
                IsValidated++;
                setChoix([{index: indexForm, value: props.candidature.DiplomeActuel.Nom}, {index: indexOption, value: props.candidature.DiplomeActuel.Options[0]}])
            }
        }
            if(props.candidature.ActuellementApprenti !== null){
                IsValidated++;
                if(props.candidature.ActuellementApprenti === true){
                    setIsApprenti("Oui");
                }else{
                    setIsApprenti("Non");
                }
            }

        if(IsValidated === 2){
            setSituationValidated(true);
        }
        setSelectedFormation(props.candidature.Formations);
    }, [props.candidature]);


    let CurModel= modeleStep1.map(a => {return {...a}});

    const setIsApprentiCB = (message) => {
        setIsApprenti(message);
        if(IsApprenti !== null && choix !== null){
            setSituationValidated(true);
        }
    }

    const confirmSituationInit = (cb) => {
        seterrorApprenti(null);
        seterrorSituAct(null);
        setSituationValidated(false);
        if(IsApprenti === null){
            setSituationValidated(false);
            seterrorApprenti("Vous devez obligatoirement répondre à cette question.")
            cb();
            return
        }
        if(choix === null){
            seterrorSituAct("Vous devez obligatoirement préciser votre formation.");
            setSituationValidated(false);
            cb();
            return
        }
        if(choix.length === 1 && choix[0].value === "Autre" && AutreTxt === ""){
            seterrorSituAct("Vous devez obligatoirement préciser votre formation.");
            setSituationValidated(false);
            cb();
            return
        }else if(choix.length === 1 && choix[0].value !== "Autre"){
            seterrorSituAct("Vous devez préciser votre option.");
            setSituationValidated(false);
            cb();
            return
        }
        let PostAr = {};
        let PostPayload = {};
        console.log(choix);
        PostPayload['DiplomeActuelAutre'] = "";
        if(choix[0].value === "Autre"){
            PostPayload['DiplomeActuelAutre'] = AutreTxt;
        }else{
            PostPayload['DiplomeActuel'] = {Nom: choix[0].value, Options: [choix[1].value]};
        }
        PostPayload['ActuellementApprenti']= (IsApprenti === "Oui");

        PostAr['Candidat'] = PostPayload;

        // Execution Request
        props.PostData(PostAr, 2, (ErrorsMessages) => {
            console.log("Error");
            console.log(ErrorsMessages);
           // errorcallback(ErrorsMessages);
        }, () => {
            setSituationValidated(true);
            console.log("OK");
        });

        return;
    }

    const confirmChoixForm = (done) => {
        let PostAr = {};
        PostAr['Formations'] = structuredClone(selectedFormation);

        let FormationSelected = false;

        PostAr['Formations'].forEach((data) => {
            let r  = data.CampusList.some(function(e, index) {
                return e.IsCandidat === true;
            });
            if(r){
                FormationSelected = true;
            }
        });

        if(!FormationSelected){
            seterrorSelectedFormation("Vous devez choisir au moins une formation.");
            done();
            return;
        }

        // Execution Request
        props.PostData(PostAr, 2, (ErrorsMessages) => {
            console.log("Error");
            console.log(ErrorsMessages);
            done();
            // errorcallback(ErrorsMessages);
        });
    }
/*
    CurModel.forEach((m, i) => {
        if(props.candidature[m.name] !== undefined && props.candidature[m.name] !== null && props.candidature[m.name] !== ""){
            console.log("Ok pour " + m.name +" >> val : " + props.candidature[m.name])
            CurModel[i].value = props.candidature[m.name];
        }
    })

    let emailIndex = CurModel.findIndex((obj => obj.name === "Email"));
*/




    return (
        <>
            <div className="alert mt-4  alert-info maxWidth m-auto"
                 role="alert">
                Si votre formation ou votre diplôme n'est pas présente dans la liste, veuillez choisir "Autre".
            </div>

        <div className="card mt-4 maxWidth m-auto mb-4">
            <div className="card-header">
                Votre situation
            </div>
            <div className="card-body p-md-5">
                <SituationActuelle notifUpdate={notifUpdate} choix={choix} setChoix={setChoix} AutreTxt={AutreTxt} setAutreTxt={setAutreTxt} error={errorSituAct} nullError={seterrorSituAct} />
                <SituationApprenti notifUpdate={notifUpdate} value={IsApprenti} setIsApprenti={setIsApprenti} error={errorApprenti} nullError={seterrorApprenti} />

                {situationValidated === false && <div className={"text-center"}><Button nom={"Continuer"} type={TypePrimary} disableOnClick={true} spinnerOnClick={true} clickCallback={confirmSituationInit} /></div>}

            </div>
        </div>

            {situationValidated === true &&  <div className="card mb-4 maxWidth m-auto">
                <div className="card-header">
                    Votre formation à l'ESITC Paris
                </div>
                <div className="card-body p-md-5">
                    Vous avez la possibilité d'intégrer l'école sous plusieurs statuts et sur différents campus. Merci d'indiquer la (ou les) formation(s) à la(les)quelle(s) vous désirez candidater :

                    {  selectedFormation.map((a, i) => {
                        let CandAge = computeAge(props.candidature.DateNaissance, a.DateCalculAge);
                        let tooOld = false;

                        if(a.AgeMax < CandAge && (a.NotLimitApprenti === false || a.NotLimitApprenti === true && props.candidature.ActuellementApprenti === false)){
                            tooOld = true;
                            OneDisable = true;
                        }

                        if(a.Disponible === false){
                            tooOld = true;
                            OneDisable = true;
                        }
                        /*a.AgeMax
                        a.DateCalculAge
                        a.NotLimitApprenti*/
                        return  a.CampusList.map((a2, i2) => {
                            // Avons-nous un dépassement de l'age limite ?


                            return <CheckBoxFormation Disable={tooOld} Checked={a2.IsCandidat} OnChange={(e) =>{
                                let newSelectedFormation = structuredClone(selectedFormation);
                                newSelectedFormation[i].CampusList[i2].IsCandidat = !newSelectedFormation[i].CampusList[i2].IsCandidat;
                                setSelectedFormation(newSelectedFormation);

                        }

                        } key={"0-"+i+"-"+i2} i={"0-"+i+"-"+i2} Nom={<>{a.Nom}<br />{a2.Nom}</>} />})

                    })}
                    {OneDisable === true && <div className="alert mt-4  alert-info maxWidth m-auto"
                                                 role="alert">Certaines options ne sont pas accessibles (dépassement de la limite d'âge ou formation non disponible).
                    </div>}

                    {errorSelectedFormation !== null && <div className="alert mt-4  alert-danger maxWidth m-auto"
                                                             role="alert">{errorSelectedFormation}</div>}
                    {selectedFormation.length !== 0 && <div className={"text-center m-5"}><Button nom={"Valider"} type={TypePrimary} disableOnClick={true} spinnerOnClick={true} clickCallback={confirmChoixForm} /></div>}
                </div>
            </div>}



        </>

    );
}

export default RegisterFormStep2;