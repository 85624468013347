function SendConfirmLink(session, authToken, callback){
    let param = [];
    param["AuthenticityToken"] = authToken
    session.ReqPost("/api/secure/inscription/send-mail-validation", function (response) {
        return response.text().then(function(text) {
            if(text === "OK"){
                callback(true);
            }else{
                callback(false);
            }
        });
    }, param)
}

export default SendConfirmLink;