import React, {useState} from 'react';

const TypePrimary = "primary";
const TypeSecondary = "secondary";

function Button(param) {
    const [disabled, setDisabled] = useState(false);
    const [loading, setloading] = useState(false);
    const onclick = () => {
        if(param.disableOnClick !== undefined && param.disableOnClick === true){
            setDisabled(true);
        }
        if(param.spinnerOnClick !== undefined && param.spinnerOnClick === true){
            setloading(true);
        }
        if(param.clickCallback !== undefined){
            param.clickCallback(() =>{
                setDisabled(false);
                setloading(false);
            });
        }
    }
    return (

        <button type="button"
                className={"btn btn-"+param.type+((param.className !== undefined) ?  " " + param.className : '')} disabled={disabled} onClick={onclick}>{loading === true && <span className={"spinner-grow spinner-grow-sm me-2"} role="status" aria-hidden="true"></span>}{param.nom}</button>
    );
}
export { TypePrimary,  TypeSecondary};
export default Button;
