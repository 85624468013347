import { checkAtLeastLength, checkIsfilled } from "../../../helper/form/inputValidator";
import { parseOnlyNumberSpaceAndPlus} from "../../../helper/form/inputParser";

const ModeleStep2 = [
   {
        name: 'Email',
        label: 'Email',
        isDisable: true,
        HelpText: "L'adresse utilisée pour la création de votre compte est obligatoirement utilisée.",
        type: 'text',
        //parseFun: parseOnlyUTF8Printable,
        validators: []
    }
];

export default ModeleStep2;