import React, {useState} from 'react';
import '../../App.scss';
import { useNavigate } from "react-router-dom";
import Button, {TypePrimary} from "../../bootstrap/button";
import SendConfirmLink from "../../firebase/SendMail";


function MailConfirmAfterLoginPage(props) {
    const navigate = useNavigate();
    const [newMailSent, setnewMailSent] = useState(false);

    const SendMail = () => {

        if(props.session.IsEmailConfirmed() === false){
            props.session.GetUserIdToken().then(idToken => {
                SendConfirmLink(props.session, idToken, function (IsSent) {

                    props.session.Logout(function () {
                            if (IsSent === true) {
                                setnewMailSent(true);
                            }else{
                                setnewMailSent("NOTSENT");
                            }

                        });
                })
            });
        }
    }

    return (
        <div className="row">
            <div className="col col-lg-6 col-sm-8 offset-sm-2 offset-lg-3">
                <div className="alert alert-danger"
                     role="alert">
                    Vous n'avez toujous pas confirmé votre compte.<br />
                    <strong>Un mail contenant un lien d'activation vous a déjà été envoyé.</strong>
                </div>

                {props.session.IsLoggedIn() === true && <Button type={TypePrimary} nom={"Me renvoyer un lien d'activation"} clickCallback={SendMail}></Button> }
                {newMailSent === true && <div className="alert alert-success"
                                              role="alert">
                    Un mail contenant un lien d'activation vient de nous être envoyé à nouveau. (Veuillez vérifier vos spams).
                </div>}
                {newMailSent === "NOTSENT" && <div className="alert alert-warning"
                                              role="alert">
                    Nous n'avons pas pu vous renvoyer un email d'activation.<br /><strong>Veuillez patienter 20 min puis essayer à nouveau</strong>.

                </div>}
                <div className="alert alert-light"
                     role="alert">
                    NOTA : Penser à vérifier vos spams.
                </div>
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default MailConfirmAfterLoginPage;
