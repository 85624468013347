let DefautModal = {
    Titre: "",
    Content: "",
    CloseCrossCallBack: null,
    BottomBtns: [],
}
function Modal(props) {

    let ModalPram = DefautModal;
    if(props.param !== undefined){
        ModalPram = props.param;
    }

    const CloseCross = () => {
        if(ModalPram.CloseCrossCallBack !== undefined){
            ModalPram.CloseCrossCallBack();
        }

    }

    return (

        <div className="modal d-block bg-opacity-75 bg-dark"
             tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ModalPram.Titre}</h5>
                        {ModalPram.CloseCrossCallBack !== undefined &&
                        <button type="button"
                                className="btn-close"
                                aria-label="Close" onClick={CloseCross}></button> }
                    </div>
                    <div className="modal-body">
                        {ModalPram.Content}
                    </div>
                    {ModalPram.BottomBtns !== undefined && ModalPram.BottomBtns.length > 0 &&
                    <div className="modal-footer">
                        {ModalPram.BottomBtns.map(e => e)}

                    </div>
                    }
                </div>
            </div>
        </div>
    );
}
/*                         <Button type={TypeSecondary} nom={"Close"} />
                        <Button type={TypePrimary} nom={"Save Change"} />*/
export default Modal;
