import "./style.scss"
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
function CheckPaiement(props) {
    const navigate = useNavigate();
    const checkPaiementState = () => {

    props.GetData("checkpaiement", () => {
        console.log("Erreur");
    }, (data) => {
        if(data.Param === "NO_PENDING_ERROR"){
            console.log("En attente confirmation.");

            navigate("/mescandidatures/"+props.ConcoursId+"/"+props.CandidatureId+"/11");
            return
        }
        if(data.Param === "MOVE_HOME"){ // Okay, on retourne sur la page d'accueil
            props.GlobalStateValid();
        }
        // on temporise.
    } )

    }
    useEffect(() => {
        const interval = setInterval(function() {
            checkPaiementState()
        }, 5000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <div className={"text-center"}>

            <div className="lds-default mt-5">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p className={"lead"}><span  style={{fontWeight: "bold"}}>Nous effectuons les dernières vérifications auprès de votre banque.</span><br />
                Veuillez patienter sans rafraichir la page.<br /><small>L'opération peut prendre jusqu'à 10 min.</small></p>

        </div>

    );
}

export default CheckPaiement;