
/*BTS
   • Bâtiment
   • TP
- DUT/BUT Génie Civil Construction Durable
- Conducteurs de Travaux ESTP
* CB
* CTP
* CAB
* CATP
- Licence Génie Civil
*/
import {useEffect, useState} from "react";
import SelectInput from "../../../helper/components/inputs/SelectInput";
import Button, {TypeSecondary} from "../../../bootstrap/button";

import DeleteBTN from "../../edit.svg"
import TextInput from "../../../helper/components/inputs/TextInput";
import curFormation from "./curFormation";


function SituationActuelle(props) {
    const [modeAutre, setModeAutre] = useState(false);

    useEffect(() => {
        if (props.choix !== null && props.choix[0].value === "Autre" && modeAutre === false){
            setModeAutre(true);
        }
    }, [props.choix])

    const onAutreTxt = (event) => {
        props.nullError(null);

        props.setAutreTxt(event.target.value);
    }

    const onChangeList = (event) => {
        props.nullError(null);
        let index = curFormation.findIndex(object => {
            return object.nom === event.target.value;
        });
        if(event.target.value === "Autre"){
            setModeAutre(true);
        }
        props.setChoix([{index : index, value: event.target.value}]);
    }

    const onChangeOption = (event) => {
        props.nullError(null);
        let index = curFormation[props.choix[0].index].options.findIndex(object => {
            return object === event.target.value;
        });
        if(event.target.value === "Autre"){
            setModeAutre(true);
            props.setAutreTxt("");
        }
        props.setChoix([{index : props.choix[0].index, value: props.choix[0].value}, {index : index, value: event.target.value}]);
    }


    if (props.choix === null){
        let SitueList = [];
        curFormation.forEach((value) => {
            SitueList.push({name: value.nom, value: value.nom});
        })

        console.log(SitueList);
       return <>{SelectInput({name: "curSitu", label: "Formation BAC+2/3", options: SitueList, setInputs: onChangeList})}{props.error !== null && <div className="alert alert-danger"
                                                                                                                                                        role="alert">
           {props.error}
       </div>}</>
      /*  return curFormation.map( (e, i) => {
            return <div>{Object.keys(e)[0]}</div>;
        })*/
    }



    if (props.choix.length === 1){
        let ChoixOptions = "";
        if (curFormation[props.choix[0].index].options.length > 0 ){
            let SitueList = [];
            curFormation[props.choix[0].index].options.forEach((value) => {
                SitueList.push({name: value, value: value});
            })
            ChoixOptions = SelectInput({name: "curSitu", label: "Option", options: SitueList, setInputs: onChangeOption})
        }

        return <div><div>Vous avez choisi : <strong>{props.choix[0].value}</strong>. <Button nom={"Modifier"} type={TypeSecondary} clickCallback={function () {
            props.setChoix(null);
            setModeAutre(false);
        }}/></div>

            {modeAutre === false && <div className={"mt-3"}>Veuillez préciser votre option :<div className={"mt-2"}>{ChoixOptions}</div></div> }
            {modeAutre === true && <div className={"mt-3"}><TextInput setInputs={onAutreTxt} value={props.AutreTxt} type={"text"} label={"Préciser votre diplôme obtenu (ou en cours de préparation) :"} name={"AutreDetail"} /></div> }
            {props.error !== null && <div className="alert alert-danger"
                                          role="alert">
                {props.error}
            </div>}
        </div>
    }


    if (props.choix.length === 2){

        return <div className={"text-center"}>Votre formation actuelle ou votre formation déjà obtenue est  : <br /><strong>{props.choix[0].value}</strong> - <strong>{props.choix[1].value}</strong>. <br /><br /><Button nom={"Modifier"} type={TypeSecondary} clickCallback={function () {
            props.setChoix(null);
            props.notifUpdate();
            setModeAutre(false);
        }}/>{props.error !== null && <div className="alert alert-danger"
                                          role="alert">
            {props.error}
        </div>}</div>

    }


}


export default SituationActuelle;