import { useState} from "react";
import "./CheckboxFormation.scss"
function CheckBoxFormation(props) {
        return (
            <div className={"form-check form-switch mt-4"} key={props.i}>
                <input className="form-check-input"  style={{cursor: ((props.Disable)? " not-allowed": "pointer")}}
                       type="checkbox"
                       role="switch"
                       checked={props.Checked}
                       onChange={props.OnChange}
                       disabled={props.Disable}
                       id={"formation-"+props.i} />
                <label className={"form-check-label prevent-select" + ((props.Checked) ? " CheckBoxFormationActif": "")} style={{cursor: ((props.Disable)? " not-allowed": "pointer")}}
                       htmlFor={"formation-"+props.i}>{props.Nom}</label>
            </div>
        );
}

export default CheckBoxFormation;