

function UserWithEmailPasswdGetTextByCode(code: string) :string {
    switch (code) {
        case 'auth/email-already-in-use':
            return "Cette adresse mail est déjà utilisée.";
        case 'auth/invalid-email':
            return "L'adresse mail est invalide.";
        case 'auth/operation-not-allowed':
            return "L'inscription n'est pas encore autorisée. Veuillez revenir plus tard.";
        case 'auth/weak-password':
            return "Votre mot de passe est trop court (6 min).";
        case 'auth/user-not-found':
            return "Votre adresse mail n'existe pas.";
        case 'auth/wrong-password':
            return "Votre mot de passe est incorrect.";
        case 'passwordMismatch':
            return "Les mots de passe ne sont pas identiques.";
        default:
            return "Erreur inconnue. Veuillez réessayer.";
    }
}

export default UserWithEmailPasswdGetTextByCode;
