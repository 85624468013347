import React from 'react';
import '../../App.scss';
import {useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import Input, {TypeEmail, TypePassword} from "../../bootstrap/input";
import UserWithEmailPasswdGetTextByCode from "../../firebase/UserWithEmailAndPassword_errors";

const loginModelDefault = [{
    name: "emailLogin",
    label: "Adresse mail",
    type: TypeEmail,
},{
    name: "passwdLogin",
    label: "Mot de passe",
    type: TypePassword,
}
];

function LoginPage(props) {
    const navigate = useNavigate();

    const [Model, setModel] = useState(loginModelDefault);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const onChange = (name, newValue) => {
        if(ErrorMessage !== null){
            setErrorMessage(null);
        }

        let NewModel = structuredClone(Model);
        let objIndex = NewModel.findIndex((obj => obj.name === name));
        NewModel[objIndex].value = newValue;
        setModel(NewModel);
    }

    const onSubmit = (e) => {
        e.preventDefault();


        let emailIndex = Model.findIndex((obj => obj.name === "emailLogin"));
        let passwdIndex = Model.findIndex((obj => obj.name === "passwdLogin"));


        if((Model[emailIndex].value !== undefined && Model[emailIndex].value === "") || Model[emailIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/invalid-email'));
            return;
        }

        if((Model[passwdIndex].value !== undefined && Model[passwdIndex].value === "") || Model[passwdIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/weak-password'));
            return;
        }

        props.session.SignInWithEmailAndPassword(Model[emailIndex].value, Model[passwdIndex].value, CallbackRegister)
    }

    const CallbackRegister = (r, data) => {
        if(r === "NOK"){
            console.log(r);
            console.log(data);
            setErrorMessage(UserWithEmailPasswdGetTextByCode(data.errorCode));
            return;
        }else{
            if(data.user.emailVerified === false){
                navigate("/login/mail-confirm");
                return;
            }
            navigate("/");
        }

    }

    return (
        <div className="row">
            <div className="col col-lg-4 col-sm-8 offset-sm-2 offset-lg-4">
                <h2>Se connecter</h2>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    {Model.map(input => Input(input, ErrorMessage !== null, onChange))}

                    {ErrorMessage !== null && <div className="alert alert-danger"
                                                   role="alert">
                        {ErrorMessage}
                    </div> }

                    <div id="emailHelp"
                         className="form-text mb-3"><Link to={"/login/reset-password"}>Mot de passe oublié ?</Link>
                    </div>

                    <button type="submit"
                            className="btn btn-primary">Me connecter
                    </button>
                </form>
            </div>
        </div>


    );
}
// helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default LoginPage;
