const curFormation = [
    {nom: "BTS", options: ['Bâtiment', 'TP']},
    {nom: "DUT/BUT", options: ["Génie Civil Construction Durable"]},
    {nom: "ESTP", options: ["Bachelor technologique - Conducteur de travaux", "Bachelor Sciences et ingénierie - Manager de la construction", "Bachelor architecture & construction", "Licence professionnelle Management et conduite de travaux"]},
    {nom: "Licence", options: ["L3 - Génie Civil"]},
    {nom: "ESITC Paris", options: ["TC2"]},
    {nom: "Autre", options:[]},

]

export default curFormation;