import React, {useEffect, useState} from "react";
import "./style.scss"

import ProgressStep from "../../helper/ProgressStep/ProgressStep";
import RegisterFormStep1 from "./step1/registerFormStep1";
import RegisterFormStep2 from "./step2/registerFormStep2";
import RegisterFormStep3 from "./step3/registerFormStep3";
import {useNavigate} from "react-router-dom";
import RegisterFormStep4 from "./step4/registerFormStep4";
import CheckPaiement from "./checkpaiement/checkpaiement";

const EtatLimiteAgedepassee = 3;
const EtatCandidatCasParticulierEnAttente = 1;
const EtatCandidatDossierDeposePaye = 5;
const EtatCandidatHorsDelai = 6;
const EtatCandidatNonAutorise = 2;
const EtatCandidatAdmissible = 7;
const EtatCandidatNonAdmissible = 8;

function Main(props){



    return <>
        <ProgressStep ListStep={[
            {Num: 1, text: "Données Personnelles"},
            {SpaceType: true},
            {Num: 2, text: "Formations"},
            {SpaceType: true},
            {Num: 3, text: "Documents Justificatifs"},
            {SpaceType: true},
            {Num: 4, text: "Paiement & Validation"},
        ]} ConcoursId={props.ConcoursId} CandidatureId={props.CandidatureId} MaxStep={props.candidature.MaxStep} CurStep={props.candidature.CurStep} />
        {props.candidature.CurStep === 1 && props.candidature.MaxStep >= 1 && <RegisterFormStep1 candidature={props.candidature} PostData={props.PostData} />}

        {props.candidature.CurStep === 2 && props.candidature.MaxStep >= 2 && <RegisterFormStep2 candidature={props.candidature} concours={props.concours} PostData={props.PostData} />}

        {props.candidature.CurStep === 3 && props.candidature.MaxStep >= 3 && <RegisterFormStep3 candidature={props.candidature} concours={props.concours} PostData={props.PostData} PostPreUpload={props.PostPreUpload} GetData={props.GetData} />}

        {props.candidature.CurStep === 4 && props.candidature.MaxStep >= 4 && <RegisterFormStep4 candidature={props.candidature} concours={props.concours} PostData={props.PostData} PostPreUpload={props.PostPreUpload} GetData={props.GetData} />}

        {props.candidature.CurStep === 11 && <RegisterFormStep4 candidature={props.candidature} concours={props.concours} PostData={props.PostData} PostPreUpload={props.PostPreUpload} GetData={props.GetData} paiementError={true} />}

        {props.candidature.CurStep > 1 && props.candidature.CurStep !== 10 && props.candidature.CurStep !== 11 && <div className={"text-center"}>
            <button type="button"
                    className="btn btn-secondary m-4" onClick={() => props.Retour(props.candidature.CurStep)}>Précédent
            </button></div>}

        {props.candidature.CurStep === 10 && <CheckPaiement GetData={props.GetData} ConcoursId={props.ConcoursId} CandidatureId={props.CandidatureId} GlobalStateValid={props.GlobalStateValid} />}


        </>;
}

function DispatchTC3(props){
    const navigate = useNavigate();

    useEffect(() => {
        if(props.candidature.CurStep*1 !== 10 && props.candidature.CurStep*1 !== 11 ){
            if(props.candidature.CurStep > props.candidature.MaxStep){
                navigate("/mescandidatures/"+props.ConcoursId+"/"+props.CandidatureId+"/"+props.candidature.MaxStep);
            }
        }



    }, [])

    if(props.Param !== undefined){
            props.candidature.CurStep = props.Param*1;
    }
   // let numDisplay = props.NumCandidature*1;
    // <p>Numéro de dossier : <strong><span className="font-monospace">{numDisplay.toString(36)}</span></strong></p>
    return <>
        <h2 className={"text-center text-uppercase"}>Dossier de candidature</h2>
        <h4 className={"text-center text-uppercase"}><div dangerouslySetInnerHTML={{ __html: props.concours.Titre }} className={"text-muted"}></div></h4>
        <hr />


        {props.candidature.Etat === EtatLimiteAgedepassee && <div className="alert alert-danger"
                                                                  role="alert">
            <strong>Votre situation ne vous permet pas de participer à ce concours.</strong><br /><br />
            <u>Motif</u> : L'age limite autorisé est dépassé.
        </div> }

        {props.candidature.Etat === EtatCandidatCasParticulierEnAttente && <div className="alert alert-info"
                                                                  role="alert">
            Votre profil scolaire ne fait pas partie de la liste prédéfinie pour postuler dans notre établissement.<br />
            <br />
            <strong>Un membre du jury de concours doit pré-analyser votre dossier afin de s'assurer que votre profil vous permette de postuler au concours.</strong><br />
            <br />
            Vous serez notifié(e) par courriel de la décision prise :<br />
            1. Le préjury vous autorise à participer : vous pourrez finaliser votre dossier en procédant au paiement des frais de concours.<br />
            OU<br />
            2. Le préjury ne vous autorise pas à participer : votre dossier restera verrouillé et vous ne pourrez pas poursuivre votre candidature.<br />
        </div> }

        {props.candidature.Etat === EtatCandidatNonAutorise && <div className="alert alert-warning text-center"
                                                                                role="alert">
            Nous avons le regret de vous informer qu'après préanalyse de votre dossier par le jury de concours, vous ne présentez pas les prérequis pour candidater.

        </div> }

        {props.candidature.Etat === EtatCandidatHorsDelai && <div className="alert alert-warning text-center"
                                                                    role="alert">

            Votre candidature n'a pas été transmise et validée dans les temps. Vous ne pouvez plus postuler.

        </div> }



        {props.candidature.Etat === EtatCandidatDossierDeposePaye && <><div className="alert alert-success text-center"
                                                                                role="alert">

            <p className={"lead"} style={{fontWeight: "bold"}}>Votre dossier de candidature a été transmis au jury de concours. Vous serez contacté par email.</p>


        </div>

        </>}


        {props.candidature.Etat === EtatCandidatAdmissible && <><div className="alert alert-success text-center"
                                                                          role="alert">

            <p className={"lead"} style={{fontWeight: "bold"}}>Après étude de votre dossier de candidature, le jury de concours vous a déclaré(e) ADMISSIBLE.<br />Un email contenant la date de convocation à l'entretien vous a été envoyé.</p>


        </div>          </>}

        {props.candidature.Etat === EtatCandidatNonAdmissible && <div className="alert alert-danger text-center"
                                                                      role="alert">

            <p className={"lead"} style={{fontWeight: "bold"}}>Après étude de votre dossier de candidature, nous avons le regret de vous informer que le jury de concours vous a déclaré(e) NON ADMISSIBLE.</p>

        </div> }

        {props.candidature.Etat === 0 && <Main ConcoursId={props.ConcoursId} CandidatureId={props.CandidatureId} candidature={props.candidature} concours={props.concours} PostData={props.PostData} PostPreUpload={props.PostPreUpload} GetData={props.GetData} Retour={props.Retour} GlobalStateValid={props.GlobalStateValid} /> }

    </>;
}

export default DispatchTC3;
/*
          Le paiement des frais de concours a bien été validé.<br /><br />
         Nous reviendrons vers vous par courriel pour vous informer de la suite donnée à votre candidature. <br />Il vous est fortement conseillé de consulter régulièrement votre adresse mail (et de vérifier vos spams).*/