import React from 'react';
import '../../App.scss';
import {useState} from "react";
import { useNavigate } from "react-router-dom";
import Input, {TypeEmail, TypePassword} from "../../bootstrap/input";
import UserWithEmailPasswdGetTextByCode from "../../firebase/UserWithEmailAndPassword_errors";
import SendConfirmLink from "../../firebase/SendMail";

const loginModelDefault = [{
    name: "emailRegister",
    label: "Adresse mail",
    type: TypeEmail,
},{
    name: "passwdRegister",
    label: "Mot de passe",
    type: TypePassword,
},{
    name: "passwdConfirmRegister",
    label: "Confirmer votre mot de passe",
    type: TypePassword,
}
];

function HomePage(props) {
    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);

    const [Model, setModel] = useState(loginModelDefault);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const onChange = (name, newValue) => {
        if(ErrorMessage !== null){
            setErrorMessage(null);
        }

        let NewModel = structuredClone(Model);
        let objIndex = NewModel.findIndex((obj => obj.name === name));
        NewModel[objIndex].value = newValue;
        setModel(NewModel);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(Loading === true){ // On évite les doubles POST
            return;
        }

        let emailIndex = Model.findIndex((obj => obj.name === "emailRegister"));
        let passwdIndex = Model.findIndex((obj => obj.name === "passwdRegister"));
        let passwd2Index = Model.findIndex((obj => obj.name === "passwdConfirmRegister"));


        if((Model[emailIndex].value !== undefined && Model[emailIndex].value === "") || Model[emailIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/invalid-email'));
            return;
        }

        if((Model[passwdIndex].value !== undefined && Model[passwdIndex].value === "") || Model[passwdIndex].value === undefined){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('auth/weak-password'));
            return;
        }

        if((Model[passwd2Index].value !== undefined && Model[passwd2Index].value !== Model[passwdIndex].value || Model[passwd2Index].value === undefined)){
            setErrorMessage(UserWithEmailPasswdGetTextByCode('passwordMismatch'));
            return;
        }

        setLoading(true);
        props.session.CreateUserWithEmailAndPassword(Model[emailIndex].value, Model[passwdIndex].value, CallbackRegister)
    }

    const CallbackRegister = (r, data) => {
        if(r === "NOK"){
            setLoading(false);
            setErrorMessage(UserWithEmailPasswdGetTextByCode(data.errorCode));
            return;
        }else{
            if(data.user.emailVerified === false){
                data.user.getIdToken().then(idToken => {
                    SendConfirmLink(props.session, idToken, function () {
                        let email = data.user.email;
                        props.session.LogoutWithUserObj(data.user, function () {
                            navigate("/register/mail-confirm", {state: {"EmailReg": email}});
                        })

                    })
                });

                return;
            }
            navigate("/");
        }
    }

    return (
        <div className="row">
            <div className="col col-lg-4 col-sm-8 offset-sm-2 offset-lg-4">
                <h2>Créer un compte</h2>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    {Model.map(function (input) {
                        let IsInvalid = ErrorMessage !== null;

                        if(input.name === "emailRegister" && ErrorMessage === UserWithEmailPasswdGetTextByCode('passwordMismatch')){
                            IsInvalid = false;
                        }


                        return Input(input, IsInvalid, onChange);
                    })}

                    {ErrorMessage !== null && <div className="alert alert-danger"
                         role="alert">
                        {ErrorMessage}
                    </div> }

                    {Loading === false &&
                    <button type="submit"
                            className="btn btn-primary">Créer mon compte
                    </button>}
                    {Loading === true &&
                    <button className="btn btn-primary"
                            type="button"
                            disabled>
                        <span className="spinner-grow spinner-grow-sm me-1"
                              role="status"
                              aria-hidden="true"></span>
                        Chargement...
                    </button>}


                </form>

            </div>
        </div>


    );
}
 // helper={"Votre adresse mail sera utilisé pour vous contacter dans le cadre du concours."}
export default HomePage;
