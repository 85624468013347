/*
newCandidature : Généère une nouvelle procédure de dépot d'une candidature.
 */


import React, {useEffect, useState} from "react";
import "./dispatch.scss"
import {useNavigate, useParams} from "react-router-dom";
import Button, {TypePrimary} from "../bootstrap/button";
import DispatchTC3 from "./tc3/dispatchTC3";

const GlobalStateLoading = "Loading";
const GlobalStateErrorNotConnected = "ErrorNotConnected";
const GlobalStateEnCoursDepotDossier = 0;
const GlobalStateEnCoursChoixFormation = 1;
const GlobalStateDossierDeposePaye = 5;

function CandidatureDispatch(props){
    const navigate = useNavigate();
    let { ConcoursId, CandidatureId, Param } = useParams();

    const [globalState, setGlobalState] = useState(GlobalStateLoading);
    const [concoursModel, setConcoursModel] = useState(null);
    const [candidatureModel, setCandidatureModel] = useState(null);

    const Retour = (Step) => {
        navigate("/mescandidatures/"+ConcoursId+"/"+CandidatureId+"/"+(Step-1))
    }

    const GlobalStateValid = () => {
        let newCandidature = structuredClone(candidatureModel)
        console.log(newCandidature);
        newCandidature.Etat = GlobalStateDossierDeposePaye;
        console.log(newCandidature);
        setCandidatureModel(newCandidature);
    }

    const PostData = (Ar, Step, CallBackErrors, CallBackOk) => {
        props.session.GetUserIdToken().then(authToken => {
            let param = [];
            param["AuthenticityToken"] = authToken;
            param["data"] = JSON.stringify({Data: Ar, Step: Step});
            props.session.ReqPost("/api/secure/candidater/"+ConcoursId+"/"+CandidatureId, function (response) {
                return response.json().then(function(json) {
                    if(json.status !== "OK"){
                        // Erreur à gérer my dear !!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        CallBackErrors([json.Error]);
                        return
                    }
                    if(json.data.OK === false){
                        // Des erreurs sont à afficher
                        CallBackErrors(json.data.ErrorsMessages);
                        return;
                    }
                    setCandidatureModel(json.data.Candidat);
                    if(CallBackOk !== undefined){
                        CallBackOk(json.data);
                    }else{
                        window.scrollTo(0, 0)
                        navigate("/mescandidatures/"+ConcoursId+"/"+CandidatureId+"/"+(Step+1));
                    }

                });
            }, param)
        });
    }


    const PostPreUpload = (Data, CallBackErrors, CallBackOk, PathOpt) => {
        props.session.GetUserIdToken().then(authToken => {
            let param = [];
            param["AuthenticityToken"] = authToken;
            param["data"] = JSON.stringify(Data);
            props.session.ReqPost("/api/secure/candidater/"+ConcoursId+"/"+CandidatureId+"/"+PathOpt, function (response) {
                return response.json().then(function(json) {
                    if(json.status !== "OK"){
                        // Erreur à gérer my dear !!!!!!!!!!!!!!!!!!!!!!!!!!!!

                        return
                    }
                    if(json.data.OK === false){
                        // Des erreurs sont à afficher
                        CallBackErrors(json.data.ErrorsMessages);
                        return;
                    }

                    CallBackOk(json.data);


                });
            }, param)
        });
    }


    const GetData = (PathOpt, CallBackErrors, CallBackOk) => {
        props.session.GetUserIdToken().then(authToken => {
            let param = [];
            param["AuthenticityToken"] = authToken;

            props.session.ReqGet("/api/secure/candidater/"+ConcoursId+"/"+CandidatureId+"/"+PathOpt, function (response) {
                return response.json().then(function(json) {
                    if(json.status !== "OK"){
                        // Erreur à gérer my dear !!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        CallBackErrors(json)
                        return
                    }

                    CallBackOk(json.data)
                });
            }, param)
        });
    }

    useEffect(() => {
        if(props.session.sessionState === "Loading"){
            console.log("Session en cours d'initialisation.")
            return;
        }
        {props.session.DisplayUser()}
        if (props.session.IsLoggedIn() === false) {
            setGlobalState(GlobalStateErrorNotConnected);
            return;
        }
        // Chargement des models concours et candidats
        props.session.GetUserIdToken().then(authToken => {
            let param = [];
            param["AuthenticityToken"] = authToken;

            props.session.ReqGet("/api/secure/candidater/"+ConcoursId+"/"+CandidatureId, function (response) {
                return response.json().then(function(json) {
                    if(json.status !== "OK"){
                        // Erreur à gérer my dear !!!!!!!!!!!!!!!!!!!!!!!!!!!!

                        return
                    }
                    setConcoursModel(json.data.Concours);
                    setCandidatureModel(json.data.Candidature);
                    console.log("Etat dossier : ", json.data.Candidature.Etat)
                    setGlobalState(json.data.Candidature.Etat);

                });
            }, param)
        });


    }, [props.session.sessionState]);


    if (GlobalStateErrorNotConnected === globalState){
        // On affiche un message de chargement.
        return <div className="alert alert-warning text-center"
                    role="alert">
            <p>L'accès à cette page est réservée aux utilisateurs connectés.<br /><strong>Veuillez vous connecter.</strong></p>
            <Button clickCallback={function () {
                navigate("/login");
            }}
                    className={"ms-2"}
                    type={TypePrimary}
                    nom={"Me connecter"} />
        </div>;
    }

    if (GlobalStateLoading === globalState){
        // On affiche un message de chargement.
        return <div className="alert alert-light text-center"
                    role="alert">
            <span className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true" /> Veuillez patienter.<br />Chargement de votre dossier en cours...
        </div>;
    }

    if(concoursModel.FormModele === "ConcoursTC3") {
        return <DispatchTC3 Param={Param} concours={concoursModel} candidature={candidatureModel} NumCandidature={CandidatureId} ConcoursId={ConcoursId} CandidatureId={CandidatureId} PostData={PostData} PostPreUpload={PostPreUpload} GetData={GetData} Retour={Retour} GlobalStateValid={GlobalStateValid} />;
    }

    return "Erreur, impossible de charger votre dossier.";
}

export default CandidatureDispatch;