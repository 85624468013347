import React from 'react';
import ValidationAlert from '../ValidationAlert';

const MulticheckboxInput = ({ name, label, value, alert, options, setInputs }) => {

    let InputClass = "form-check-input"
    if (typeof alert !== 'undefined' &&  alert != null && alert.length > 0) {
        InputClass = "form-check-input is-invalid"
    }else if (typeof alert !== 'undefined' &&  alert != null && alert === true) {
        InputClass = "form-check-input is-valid"
    }

    return (
        <fieldset className="row mb-3">
            <legend className="col-form-label col-sm-3 pt-0">{label}</legend>
            <div className="col-sm-9">
                {options && options.map((option, i) => {
                    let EndError = ""
                    if(i === (options.length-1)) {
                        EndError = <ValidationAlert content={alert} />
                    }
                    return <div className="form-check" key={option.value}>
                        <input className={InputClass}
                               type={"checkbox"}
                               name={name+"["+i+"]"}
                               id={name+"["+i+"]"}
                               checked={option.checked === true}
                               onChange={setInputs}
                        />
                        <label className="form-check-label"
                               htmlFor={name+"["+i+"]"}>
                            {option.name}
                        </label>
                        {EndError}
                    </div>;
                })

                }

            </div>


        </fieldset>

    )/*
    return (
        <div>
            <input id={name} name={name} type={type} value={value} checked={value || false} onChange={setInputs} className={"uk-checkbox"} />
            <label htmlFor={name} className="uk-form-label uk-margin-small-left">{label}</label>
            <ValidationAlert content={alert} />
        </div>
    )*/
}

export default MulticheckboxInput;